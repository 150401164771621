body {
  #bookero-plugin {
    font-family: Poppins;
    padding-left: 7%;
    .bookero-plugin-header {
      background: transparent;
      color: #000;
      font-size: 60px;
      padding-left: 0;
    }

    .bookero-plugin-form {
      max-width: 100%;
      margin-top: 30px;
      .field.is-required > label::after {
        opacity: 1;
      }
    }

    .bookero-plugin-form-heading {
      color: #000;
      font-size: 60px;
      font-weight: 300;

      &:first-child {
        display: none;
      }
      &.has-separator {
        display: none;
      }
    }

    h3.bookero-plugin-form-heading {
      font-size: 30px;
    }

    .services-section {
      padding: 64px 7% 140px;
      background: linear-gradient(92.74deg, #F3F810 41.62%, rgba(243, 248, 16, 0) 118.24%);
    }

    .params-section {
      padding: 64px 7% 140px;
      background: linear-gradient(92.74deg, #F3F810 41.62%, rgba(243, 248, 16, 0) 118.24%);
    }

    .bookero-plugin-form {
      .field {
        max-width: 580px;
        margin: auto;

        label {
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 72px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
    .calendar-days-list-cell.is-valid {
      background: rgba(243, 248, 16, 0.2);
      color: #000;
      &.is-selected {
        background-color: #F3F810;
        border-color: #F3F810;
      }
    }
    .hours-list-item {
      background: rgba(243, 248, 16, 0.2);
      color: #000;
      &.is-selected {
        background-color: #F3F810;
        border-color: #F3F810;
      }
    }
    .submit-button {
      background-color: #f3f810;
      color: #000000;
      &::after {
        border-color: #000;
      }
    }
    .bookero-plugin-form-wrapper a {
      color: #000;
      &:hover {
        border-bottom: 1px solid #000;
      }
      &::after {
        border-top: 2px solid #000;
        border-right: 2px solid #000;
      }
    }
    .inquiry {
      padding-left: 7%
    }
    .required-fields-notice {
    }
    .back-button {
    }
  }
}

@media screen and (max-width: 1000px) {
  body {
    #bookero-plugin {
      .bookero-plugin-header {
        font-size: 30px;
      }

      .bookero-plugin-form-heading {
        font-size: 30px;
      }

      .services-section {
        padding: 20px 7%;
      }

      .params-section {
        padding: 20px 7%;
      }
    }
  }
}